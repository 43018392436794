













import { computed, defineComponent, ref, watch, reactive, watchEffect } from '@vue/composition-api';
import Search from './Search.vue';

export default defineComponent({
  components: { Search },
  setup(_, { root }) {
    return {};
  },
});
