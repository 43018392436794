



































































import { computed, defineComponent, ref, watch, reactive, watchEffect } from '@vue/composition-api';
import { useApi } from '@/util/useApi';
import { usePatentStore } from './store';
import { getPatents, Patent } from './api';

export default defineComponent({
  props: {
    keyword: {
      type: String,
    },
  },
  setup(props, { root }) {
    const { setCurrent } = usePatentStore();

    const search = reactive({
      keyword: (props.keyword || root.$route.query.keyword) as string | undefined,
      page: 1,
      type: '全部',
      date: undefined as [string, string] | undefined,
    });

    const { result, refresh, loading } = useApi(
      async () =>
        (
          await getPatents({
            size: 10,
            minApplyDate: search.date ? search.date[0] : undefined,
            maxApplyDate: search.date ? search.date[1] : undefined,
            page: search.page,
            keyword: search.keyword,
            type: search.type,
          })
        ).data,
    );

    watch(() => [search.type, search.page, search.date], refresh);

    return {
      loading,
      search,
      types: ['全部', '发明专利', '实用新型', '外观设计', 'PCT'],
      toDetail(item: Patent) {
        setCurrent(item);
        root.$router.push({ name: 'PatentDetail' });
      },
      onPageChange: (page: number) => {
        search.page = page;
      },
      patents: computed(() => (result.value ? result.value.result : [])),
      total: computed(() => (result.value ? result.value.total : 0)),
      onSearch() {
        refresh();
      },
    };
  },
});
