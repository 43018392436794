import { createStore } from 'pinia';
import { Patent } from './api';

export const usePatentStore = createStore({
  id: 'patent',
  state: () => ({
    current: null as null | Patent,
  }),
  // optional getters
  actions: {
    setCurrent(current: null | Patent) {
      this.current = current;
    },
  },
});
